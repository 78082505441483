import { ref, watch, unref, type MaybeRef } from 'vue';
import type { ProductsQueryModel } from '~/server/api/magento/products.get';
import { CATEGORY_ID } from '~/utils/shop/constants';
import type { ProductsFragment } from '~/lib/Shop/generated/schema';
import { DEFAULT_FETCH_OPTIONS } from '~/utils/defaultFetchOptions';

type ResponseModel = ProductsFragment;

export function useProducts(
  store: string,
  locale: string,
  filters: MaybeRef<Record<string, string[]>>
) {
  const response = ref<ResponseModel>();
  const abortController = ref<AbortController>();
  const loading = ref(false);
  const error = ref<unknown>(null);
  const lastParams = ref<ProductsQueryModel>();

  const doRequest = async () => {
    const query: ProductsQueryModel = {
      store,
      locale,
      filters: unref(filters)
    };

    // avoid duplicated request.
    // XXX: better compare option?
    if (JSON.stringify(lastParams.value) === JSON.stringify(query)) {
      return;
    }

    lastParams.value = query;

    if (
      abortController.value &&
      !abortController.value.signal.aborted &&
      loading.value
    ) {
      abortController.value.abort();
    }

    const currentAbortController = new AbortController();
    abortController.value = currentAbortController;

    loading.value = true;
    error.value = null;
    try {
      const productsResponse = await $fetch<ResponseModel>(
        '/api/magento/products',
        {
          ...DEFAULT_FETCH_OPTIONS,
          query,
          signal: currentAbortController.signal
        }
      );

      // remove category uid filter if category id filter is present.
      // @see https://gcp.baslerweb.com/jira/browse/WEB2-2004
      const categoryId = productsResponse?.aggregations?.find(
        (a) => a?.attribute_code === CATEGORY_ID
      );
      const categoryUid = productsResponse?.aggregations?.find(
        (a) => a?.attribute_code === 'category_uid'
      );

      if (productsResponse?.aggregations && categoryId && categoryUid) {
        productsResponse.aggregations = productsResponse.aggregations.filter(
          (a) => a?.attribute_code !== 'category_uid'
        );
      }

      response.value = productsResponse;
    } catch (e) {
      // ignore abort exceptions
      if (currentAbortController.signal.aborted) {
        return;
      }
      error.value = e;
    } finally {
      loading.value = false;
    }
  };

  watch(filters, () => doRequest(), {
    immediate: true
  });

  return {
    response,
    loading,
    error
  };
}
