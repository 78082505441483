<template>
  <TeaserCardsBlock
    :items="teaserCardsProps"
    :grid-type="item.fields.gridType?.toLocaleLowerCase() ?? 'stretch'"
    :header-gutter="false"
    :max-columns="columnWidth"
    :translations="$textDictionary"
    :cols-centered="item.fields.teasersCenteredInGrid ?? true"
    :cols="cols"
    link-icon-position="content"
    class="teaser-cards-block"
  />
</template>

<script setup lang="ts">
import type { Props as TeaserCardProps } from '@hypercodestudio/basler-components/dist/components/modules/TeaserCard.vue';
import { TeaserCardsBlock } from '@hypercodestudio/basler-components';
import type { IProduct, ITextTeaserCardList } from '~/lib/ContentfulService';
import { generateImageObject } from '~/utils/generateImageObject';
import { isContentPage } from '~/utils/guards/isContentPage';
import { isDefined } from '~/utils/guards/isDefined';
import { isEntryOfContentType } from '~/utils/guards/isEntryOfContentType';
import { buildPdpUrl } from '~/utils/buildPdpUrl';
import { mapColsWidth } from '~/utils/mapColsWidth';
import { DecoratedProduct } from '~/utils/shop/DecoratedProduct';
import type { CommonProductFragment } from '~/lib/Shop/generated/schema';

interface Props {
  item: ITextTeaserCardList;
}

const props = defineProps<Props>();

const { $shopStore, $textDictionary } = useNuxtApp();
const locale = useLocale();

const cols = computed(() =>
  mapColsWidth(props.item.fields?.gridWith ?? '10 cols')
);
const teaserCardsProps = computed(
  () =>
    props.item.fields?.teasers
      ?.map((currentTeaser): TeaserCardProps | undefined => {
        if (isEntryOfContentType(currentTeaser, 'product')) {
          const product = decoratedProducts.value?.find(
            (product) => product.urlKey === currentTeaser.fields.urlKey
          );

          if (product) {
            return {
              content: product.productDescription ?? '',
              header: product.getProductImage({
                fit: 'pad',
                bgColor: 'rgb:ffffff'
              }) ?? { type: 'image' },
              headerGutter: false,
              headline: product.modelName ?? '',
              linkIconPosition: 'content',
              link:
                product.urlKey &&
                useBuildLinkInterface(buildPdpUrl(locale.value, product.urlKey))
            } satisfies TeaserCardProps;
          }

          if (decoratedProducts.value != null && !product) {
            // failed to load product, remove from list
            return undefined;
          }

          return {
            isLoading: true,
            content: '',
            header: { type: 'image' },
            headerGutter: false,
            headline: '',
            linkIconPosition: 'content',
            link: undefined
          };
        }

        if (isContentPage(currentTeaser)) {
          if (
            currentTeaser.fields.locales &&
            !currentTeaser.fields.locales.includes(currentTeaser.sys.locale)
          ) {
            return undefined;
          }

          const metadataFields = currentTeaser.fields.metadata?.fields;

          return {
            content: metadataFields?.teaserText,
            header: {
              type: 'image',
              content: metadataFields?.teaserImage
                ? generateImageObject(metadataFields?.teaserImage, {
                    fit: 'pad',
                    bgColor: 'rgb:ffffff'
                  })?.content
                : ''
            },
            headerGutter: false,
            headline: metadataFields?.teaserTitle,
            linkIconPosition: 'content',
            link: useBuildLinkInterface(currentTeaser)
          } satisfies TeaserCardProps;
        }

        if (
          !isDefined(currentTeaser) ||
          (isContentPage(currentTeaser.fields.link?.fields.internalPage) &&
            !currentTeaser.fields.link?.fields.internalPage?.fields?.locales?.includes(
              currentTeaser.sys.locale
            ))
        ) {
          return undefined;
        }

        return {
          content: currentTeaser.fields?.text ?? '',
          header: {
            type: 'image',
            content: currentTeaser.fields.image
              ? generateImageObject(currentTeaser.fields.image, {
                  fit: 'pad',
                  bgColor: 'rgb:ffffff'
                })?.content
              : ''
          },
          badge: currentTeaser.fields.badge?.fields.title ?? '',
          headerGutter: false,
          headline: currentTeaser.fields.headline,
          linkIconPosition: 'content',
          link: useBuildLinkInterface(currentTeaser.fields.link)
        } satisfies TeaserCardProps;
      })
      .filter(isDefined) ?? []
);
const teaserCardsProductUrlKeys = computed(
  () =>
    props.item.fields?.teasers
      ?.filter((teaser): teaser is IProduct =>
        isEntryOfContentType(teaser, 'product')
      )
      ?.map((product) => product.fields.urlKey!)
      .filter(isDefined) ?? []
);
const products = (() =>
  teaserCardsProductUrlKeys.value.length
    ? useProducts(
        $shopStore.value,
        locale.value,
        computed(() => ({
          url_key: teaserCardsProductUrlKeys.value
        }))
      )
    : undefined)();
const decoratedProducts = computed(() =>
  products?.response.value?.items
    ?.filter(isDefined)
    .map((product: CommonProductFragment) =>
      DecoratedProduct.build(product, {}, locale.value)
    )
);
const columnWidth = computed(() =>
  isDefined(props.item.fields.gridColumns)
    ? parseInt(props.item.fields.gridColumns.replace(' columns', ''))
    : 3
);
</script>
